import { IProduct } from '../../types';
import { findProductBySku } from '../../helpers/products';
import { mediPlanQuiz } from './Funnels/mediPlanQuiz';
import { cortisoldetox } from './Funnels/cortisoldetox';

export const Funnels = {
  male: mediPlanQuiz('male'),
  female: mediPlanQuiz('female'),
  malecortisoldetox: cortisoldetox('male'),
  femalecortisoldetox: cortisoldetox('female'),
  default: mediPlanQuiz('female'),
};

export const FunnelRoutes = {
  HOME: '/',
  LANDING_HOME: '/landing-home',
  LANDING_HOME_CORTISOL: '/landing-home/cortisol',
  LANDING_SHORT: '/mediterranean',
  LANDING_GOAL: '/landing-goal',
  CORTISOL: '/cortisol',
  CHAT: '/chat',
  QUIZ: '/quiz',
  CHECKING: '/checking',
  GET_STARTED: '/get-started',
  SUMMARY: '/summary',
  SUMMARY_V2: '/summary-2',
  SUMMARY_V3: '/summary-3',
  PROFILE_DATA: '/profile-data',
  PLAN_READY: '/plan-ready',
  PURCHASE_PLAN: '/purchase-plan',
  PURCHASE_PLAN_V2: '/purchase-plan-2',
  PURCHASE_PLAN_V3: '/purchase-plan-3',
  CHECKOUT: '/checkout',
  CHECKOUT_EMAIL: '/checkout-email',
  UPSELL: {
    Strategy1: {
      UPSELL_1: '/faster-upsell',
      UPSELL_2: '/recipes-upsell',
      UPSELL_3: '/premium-upsell',
      ORDER: ['/faster-upsell', '/recipes-upsell', '/premium-upsell'],
    },
    Strategy2: {
      UPSELL_1: '/goals-upsell',
      UPSELL_2: '/special-offer-upsell',
      UPSELL_3: '/recipes-upsell',
      UPSELL_4: '/flavor-upsell',
      UPSELL_5: '/dessert-upsell',
      UPSELL_6: '/faster-upsell',
      ORDER: [
        '/goals-upsell',
        '/special-offer-upsell',
        '/recipes-upsell',
        '/flavor-upsell',
        '/dessert-upsell',
        '/faster-upsell',
      ],
    },
  },
  REGISTER: '/register',
  REGISTER_APP: '/register-app',
  THANK_YOU: '/thank-you',
  NEWS: '/7-reasons-why-most-people-fail-on-the-mediterranean-diet',
};

export const UpsellsOrder = {
  default: FunnelRoutes.UPSELL.Strategy2.ORDER,
};
export const RoutesOrder = {
  default: [
    FunnelRoutes.HOME,
    FunnelRoutes.QUIZ,
    FunnelRoutes.CHECKING,
    FunnelRoutes.GET_STARTED,
    FunnelRoutes.SUMMARY,
    // FunnelRoutes.PLAN_READY,
    FunnelRoutes.PURCHASE_PLAN,
    FunnelRoutes.CHECKOUT,
    FunnelRoutes.REGISTER,
    FunnelRoutes.THANK_YOU,
  ],
};

export const ProductSkus = {
  threeMonthPlan_new: 'threeMonthPlan_new',
  oneMonthPlan_new: 'oneMonthPlan_new',
  sevenDayPlan: 'sevenDayPlan',
  oneWeekPlan: 'oneWeekPlan',
  fourWeekPlan: 'fourWeekPlan',
  twelveWeekPlan: 'twelveWeekPlan',
  premiumUpsell: 'premiumUpsell',
  fasterUpsell: 'fasterUpsell',
  recipesUpsell: 'recipesUpsell',
  goalsUpsell: 'goalsUpsell',
  luckyUpsell: 'luckyUpsell',
  specialOfferUpsell: 'specialOfferUpsell',
  flavorUpsell: 'flavorUpsell',
  dessertUpsell: 'dessertUpsell',
};

export const getProducts = (products: IProduct[]) => ({
  oneWeekPlan: findProductBySku(products, ProductSkus.oneWeekPlan),
  fourWeekPlan: findProductBySku(products, ProductSkus.fourWeekPlan),
  twelveWeekPlan: findProductBySku(products, ProductSkus.twelveWeekPlan),
  premiumUpsell: findProductBySku(products, ProductSkus.premiumUpsell),
  fasterUpsell: findProductBySku(products, ProductSkus.fasterUpsell),
  recipesUpsell: findProductBySku(products, ProductSkus.recipesUpsell),
  goalsUpsell: findProductBySku(products, ProductSkus.goalsUpsell),
  luckyUpsell: findProductBySku(products, ProductSkus.luckyUpsell),
  specialOfferUpsell: findProductBySku(products, ProductSkus.specialOfferUpsell),
  flavorUpsell: findProductBySku(products, ProductSkus.flavorUpsell),
  dessertUpsell: findProductBySku(products, ProductSkus.dessertUpsell),
  threeMonthPlan_new: findProductBySku(products, ProductSkus.threeMonthPlan_new),
  oneMonthPlan_new: findProductBySku(products, ProductSkus.oneMonthPlan_new),
  sevenDayPlan: findProductBySku(products, ProductSkus.sevenDayPlan),
});

export const ProjectName = 'Medi Plan';
